import React, { forwardRef, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useController, useForm, useFormContext } from 'react-hook-form';
import FunwooAPI, {
  AbroadConsultantPropose,
  InterestTopic,
  SendEmailAbroadDto,
  SendEmailBuyHouseConsultantMailDto,
  SendEmailInterestedBuildingDto,
  SendEmailPersonalConsultationDto,
  SendEmailSellMailDto,
  StaticOptionEntity,
  UTM,
} from '@/swagger';
import ConditionalFragment from '@/components/common/ConditionalFragment';
import { HookFormError } from '@/src/components/common/FieldError';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  HStack,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import { digitalOnly, emailValidator, phoneValidator } from '@/utils/fieldValidator';
import Link from 'next/link';
import Modal from '@/src/components/Modal/Modal';
import { ChevronDownIcon, CloseIcon, SpinnerIcon } from '@chakra-ui/icons';
import classNames from 'classnames';
import chunk from 'lodash/chunk';
import { isFalse, isNotSet, isSet, isTrue } from '@/utils/formatChecker';
import StyleModule from './consultant-form.module.scss';
import { FieldPath, FieldPathValue, FieldValues } from 'react-hook-form/dist/types';
import { UseControllerProps } from 'react-hook-form/dist/types/controller';
import { useRouter } from 'next/router';
import { useAsync } from 'react-use';
import CustomRadio from '@/src/components/UI/CustomRadio';
import ScrollableMaskContainer from '@/components/common/ScrollableMaskContainer';
import { BsArrowLeftShort } from 'react-icons/bs';
import CustomCheckbox from '@/src/components/UI/CustomCheckbox';
import { capitalize } from 'lodash';
import { FunwooSelect } from '@/src/components/common/FunwooSelect';
import { FaCheck } from 'react-icons/fa';
import Flexbox from '@/components/common/Flexbox';

export interface BasicFormValues {
  agree: boolean;
  whereDoYouKnowUsFrom: string;
}

export interface SendBuyHouseConsultantMailFormValues
  extends Omit<SendEmailBuyHouseConsultantMailDto, 'type'>,
    BasicFormValues {
  phoneCountryCode: number;
}

export interface SendInterestedBuildingMailFormValues
  extends Omit<SendEmailInterestedBuildingDto, 'type'>,
    BasicFormValues {
  phoneCountryCode: number;
}

export interface SendSellMailFormValues extends Omit<SendEmailSellMailDto, 'type'>, BasicFormValues {
  phoneCountryCode: number;
}

export interface SendAbroadMailFormValues extends Omit<SendEmailAbroadDto, 'type'>, BasicFormValues {
  phoneCountryCode: number;
}

export interface SendPersonalConsultationFormValues
  extends Omit<SendEmailPersonalConsultationDto, 'type'>,
    BasicFormValues {
  phoneCountryCode: number;
}

export const BuyHouseConsultantForm: React.FC<{ id?: string; showTitle?: boolean }> = () => {
  const [showSubmitSucceed, setShowSubmitSucceed] = useState<boolean>(false);

  const methods = useForm<SendBuyHouseConsultantMailFormValues>({
    mode: 'all',
    defaultValues: {
      phoneCountryCode: 886,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
  } = methods;

  const triggerSubmitSucceed = useCallback(() => setShowSubmitSucceed((prev) => !prev), []);

  const onSubmit = useCallback<SubmitHandler<SendBuyHouseConsultantMailFormValues>>(
    async ({ phone, phoneCountryCode, ...formValues }) => {
      await FunwooAPI.mailApi.sendEmail({
        type: 'BUY_HOUSE',
        ...formValues,
        phone: `${phoneCountryCode}-${phone}`,
      });
      reset();
      triggerSubmitSucceed();
    },
    []
  );

  return (
    <FormProvider {...methods}>
      <FormSummitedPopUp
        title={'預約諮詢'}
        show={showSubmitSucceed}
        close={triggerSubmitSucceed}
        buttonText={'返回買家頁面'}
      />
      <form className={'-mx-4 md:-mx-8 p-4 md:p-8 bg-gray50'} onSubmit={handleSubmit(onSubmit)}>
        <h2 className={'mb-4 font-medium text-center text-2xl leading-[3rem]'}>填寫購屋需求</h2>
        <p className={'mb-4 px-4 text-center leading-6'}>
          超過百位買家使用的專業買代尋服務，留下您的資訊，我們將於 24 小時內聯絡您
        </p>
        <div>
          <div className={'md:flex'}>
            <FieldSection>
              <NameField />
            </FieldSection>
            <FieldSection>
              <PhoneField />
            </FieldSection>
          </div>
          <div className={'md:flex'}>
            <FieldSection>
              <EmailField required />
            </FieldSection>
            <FieldSection>
              <Label required>其他購屋需求（區域、預算、室內坪數）或問題</Label>
              <Input {...register('comment')} />
            </FieldSection>
          </div>
          <div className={'md:flex'}>
            <FieldSection>
              <WhereDoYouKnowUsFrom popupMode darkMode={false} />
            </FieldSection>
            <FieldSection />
          </div>
        </div>
        <Agreement className={'mb-3'} />
        <button disabled={!isValid} className={'block mx-auto w-40 h-12 disabled:bg-opacity-25 text-white bg-gray900'}>
          送出資料
        </button>
      </form>
    </FormProvider>
  );
};

const ConsultantForm: React.FC<{ id?: string; showTitle?: boolean }> = ({ id, showTitle }) => {
  const [showSubmitSucceed, setShowSubmitSucceed] = useState<boolean>(false);

  const methods = useForm<SendSellMailFormValues>({
    mode: 'all',
    defaultValues: {
      phoneCountryCode: 886,
    },
  });
  const { register, handleSubmit, reset } = methods;

  const triggerSubmitSucceed = useCallback(() => setShowSubmitSucceed((prev) => !prev), []);

  const onSubmit = useCallback<SubmitHandler<SendSellMailFormValues>>(
    async ({ phone, phoneCountryCode, ...formValues }) => {
      await FunwooAPI.mailApi.sendEmail({
        type: 'SELL',
        ...formValues,
        phone: `${phoneCountryCode}-${phone}`,
      });
      reset();
      triggerSubmitSucceed();
    },
    []
  );

  return (
    <FormProvider {...methods}>
      <FormSummitedPopUp
        title={'預約諮詢'}
        show={showSubmitSucceed}
        close={triggerSubmitSucceed}
        buttonText={'返回賣屋頁面'}
      />
      <FormContainer
        id={id}
        showTitle={showTitle}
        title={'預約諮詢'}
        wording={'留下您的聯絡資訊和物件地址，以協助團隊預先製作物件分析報告，供您參考'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FieldSection>
          <NameField />
        </FieldSection>
        <FieldSection>
          <PhoneField />
        </FieldSection>
        <FieldSection>
          <EmailField />
        </FieldSection>
        <FieldSection>
          <Label required>物件地址</Label>
          <Input isValid={isSet(methods.formState.errors?.['address'])} {...register('address', { required: true })} />
        </FieldSection>
        <FieldSection>
          <WhereDoYouKnowUsFrom popupMode darkMode={false} />
        </FieldSection>
        <FieldSection />
      </FormContainer>
    </FormProvider>
  );
};

export const BestAgencyConsultantForm: React.FC = () => {
  const [showSubmitSucceed, setShowSubmitSucceed] = useState<boolean>(false);

  const methods = useForm<SendSellMailFormValues>({
    mode: 'all',
    defaultValues: {
      phoneCountryCode: 886,
    },
  });
  const { register, handleSubmit, reset } = methods;

  const triggerSubmitSucceed = useCallback(() => setShowSubmitSucceed((prev) => !prev), []);

  const onSubmit = useCallback<SubmitHandler<SendSellMailFormValues>>(
    async ({ phone, phoneCountryCode, ...formValues }) => {
      await FunwooAPI.mailApi.sendEmail({
        type: 'SELL',
        ...formValues,
        phone: `${phoneCountryCode}-${phone}`,
      });
      reset();
      triggerSubmitSucceed();
    },
    []
  );

  return (
    <FormProvider {...methods}>
      <FormSummitedPopUp
        title={'預約諮詢'}
        show={showSubmitSucceed}
        close={triggerSubmitSucceed}
        buttonText={'返回賣屋頁面'}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'space-y-6 mb-10'}>
          <FieldSection>
            <NameField />
          </FieldSection>
          <FieldSection>
            <PhoneField />
          </FieldSection>
          <FieldSection>
            <EmailField />
          </FieldSection>
          <FieldSection>
            <Label required>物件地址</Label>
            <Input
              isValid={isSet(methods.formState.errors?.['address'])}
              {...register('address', { required: true })}
            />
          </FieldSection>
          <FieldSection>
            <WhereDoYouKnowUsFrom popupMode darkMode={false} />
          </FieldSection>
          <Agreement useSquareBox startLeft />
        </div>
        <button className={'py-4 w-full bg-gold font-medium text-white'}>預約諮詢</button>
      </form>
    </FormProvider>
  );
};

const budgetOptions = [
  { value: '3000w-5000w', label: '3000 萬 - 5000 萬' },
  { value: '5000w-1e', label: '5000 萬 - 1 億' },
  { value: '1e-above', label: '1 億以上' },
];

const interestingCityOptions: Array<Option> = [
  { label: '紐約 New York', value: 'New_York' },
  { label: '西雅圖 Seattle', value: 'Seattle' },
  { label: '貝爾維尤 Bellevue', value: 'Bellevue' },
  { label: '雷蒙 Redmond', value: 'Redmond' },
  { label: '柯克蘭 Kirkland', value: 'Kirkland' },
  // { label: '北加州 (Bay Area)', value: 'Bay Area' },
  { label: '北加州 Northern California', value: 'Northern California' },
  { label: '南加州 Southern California', value: 'Southern California' },
  // { label: '波士頓 Boston', value: 'Boston' },
  // { label: '芝加哥 Chicago', value: 'Chicago' },
  // { label: '舊金山', value: 'San_Francisco' },
  // { label: '洛杉磯', value: 'Los_Angeles' },
  // { label: '多倫多', value: 'Toronto' },
  // { label: '溫哥華', value: 'Vancouver' },
];
const interestTopicOptions: Array<Option<InterestTopic>> = [
  { label: '市場趨勢', value: InterestTopic.MARKETING_TREND },
  { label: '購房流程', value: InterestTopic.BUYING_PROCESS },
  { label: '貸款問題', value: InterestTopic.LOAN_CONSULTANT },
  { label: '學區諮詢', value: InterestTopic.SCHOOL_DISTRICT },
  { label: '資產傳承', value: InterestTopic.LEGACY_INHERITANCE },
  { label: '稅務規劃', value: InterestTopic.TAX_PLANNING },
];

const getDefaultAbroadFormValues = (): Partial<
  Omit<SendAbroadMailFormValues, 'propose'> & { propose: AbroadConsultantPropose }
> => ({
  phoneCountryCode: 886,
  agree: false,
  propose: AbroadConsultantPropose.INVESTMENT,
  haveIdentity: 'true',
  haveExperienced: 'true',
  interestTopic: interestTopicOptions[0]!.value,
  name: '',
  phone: '',
  email: '',
  comment: '',
  budget: budgetOptions[0].value,
  interestCity: [interestingCityOptions[0]!.value],
  // desireConferenceLocation: 'ONE_ON_ONE',
  // desireConferenceLocation: conferenceLocationOptions?.[0]?.value,
  whereDoYouKnowUsFrom: 'Facebook',
});

enum ExtraConsultantWay {
  LOOKING_FORWARD_FURTHER = 'LOOKING_FORWARD_FURTHER',
  RESERVE_PERSONAL_CONSULTATION = 'RESERVE_PERSONAL_CONSULTATION',
}

export const OverseaInvestmentConsultantForm: React.FC = () => {
  const [showSubmitSucceed, setShowSubmitSucceed] = useState<boolean>(false);
  const [successModalContent, setSuccessModalContent] = useState<{
    preferredLecture: StaticOptionEntity | undefined;
    name: string;
  }>({ preferredLecture: undefined, name: '' });

  const methods = useForm<Omit<SendAbroadMailFormValues, 'propose'> & { propose: AbroadConsultantPropose }>({
    mode: 'all',
    defaultValues: getDefaultAbroadFormValues(),
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = methods;

  const { value: conferenceLocationOptions = [] } = useAsync(async () => {
    const { data } = await FunwooAPI.systemApi.findAllStaticOptionsByCategory({ category: 'CONFERENCE_LOCATION' });

    reset({
      ...getDefaultAbroadFormValues(),
      desireConferenceLocation: data[0]?.value ?? ExtraConsultantWay.LOOKING_FORWARD_FURTHER,
    });
    return data
      .filter(({ active }) => active)
      .concat({
        value: ExtraConsultantWay.RESERVE_PERSONAL_CONSULTATION,
        label: '預約專人諮詢，請與我聯繫',
        description: '',
        active: true,
        id: ExtraConsultantWay.RESERVE_PERSONAL_CONSULTATION,
        order: 999,
        inactivated_at: null,
      })
      .concat({
        value: ExtraConsultantWay.LOOKING_FORWARD_FURTHER,
        label: '無符合日期，日後有講座通知我',
        description: '',
        active: true,
        id: ExtraConsultantWay.LOOKING_FORWARD_FURTHER,
        order: 999,
        inactivated_at: null,
      });
  }, []);
  const router = useRouter();

  const triggerSubmitSucceed = useCallback(() => setShowSubmitSucceed((prev) => !prev), []);

  const onSubmit = useCallback<
    SubmitHandler<Omit<SendAbroadMailFormValues, 'propose'> & { propose: AbroadConsultantPropose }>
  >(
    async ({ phone, phoneCountryCode, propose, agree: _, ...formValues }) => {
      const { utm_source, utm_campaign, utm_content, utm_medium } = (router.query ?? {}) as UTM;

      await FunwooAPI.mailApi.sendEmail({
        type: 'ABROAD',
        ...formValues,
        phone: `${phoneCountryCode}-${phone}`,
        propose: propose,
        utm: {
          utm_source,
          utm_campaign,
          utm_content,
          utm_medium,
          userAgent: window.navigator.userAgent,
        },
      });
      const selectedLecture = conferenceLocationOptions.find(
        ({ value }) => value === formValues.desireConferenceLocation
      );
      setSuccessModalContent({
        preferredLecture: selectedLecture,
        name: formValues.name,
      });
      reset();
      triggerSubmitSucceed();
    },
    [router, reset, conferenceLocationOptions]
  );

  const preferredLectureForModal =
    successModalContent.preferredLecture?.value ?? ExtraConsultantWay.LOOKING_FORWARD_FURTHER;
  const hasNoInterestedLecture = preferredLectureForModal === ExtraConsultantWay.LOOKING_FORWARD_FURTHER;
  const hasReservedPersonalConsultation = preferredLectureForModal === ExtraConsultantWay.RESERVE_PERSONAL_CONSULTATION;

  return (
    <FormProvider {...methods}>
      <GreetingPopup
        show={showSubmitSucceed}
        onClose={triggerSubmitSucceed}
        title={hasNoInterestedLecture || hasReservedPersonalConsultation ? '感謝您填寫資料!' : '[ 講座報名 ]'}
      >
        <p className={'font-medium text-base'}>
          {hasNoInterestedLecture || hasReservedPersonalConsultation ? '我們已收到您的資訊' : '我們已收到您的報名'}
          <br />
          {hasNoInterestedLecture
            ? '客服專員將與您聯繫確認講座日期'
            : hasReservedPersonalConsultation
            ? '客服專員將與您聯繫確認諮詢日期'
            : successModalContent.preferredLecture?.label}
          {!hasReservedPersonalConsultation && !hasNoInterestedLecture && (
            <React.Fragment>
              <br />
              客服專員將與您聯繫
            </React.Fragment>
          )}
        </p>
      </GreetingPopup>
      <form onSubmit={handleSubmit(onSubmit, console.log)} className={StyleModule['form']}>
        <p className={'mb-2 py-2'}>留下您的資訊，我們將於 24 小時內聯絡您</p>
        <p className={'mb-2 py-2 text-gold border-b border-solid border-gold'}>置產需求</p>
        <div>
          <div className={'sm:flex sm:mb-2'}>
            <div className={'mb-2 sm:mr-14 sm:mb-0 sm:flex-1'}>
              <Label darkMode required>
                美國置產用途
              </Label>
              <FunwooRadio
                control={control}
                name={'propose'}
                rules={{ required: { value: true, message: '此欄位為必填' } }}
                options={[
                  { value: AbroadConsultantPropose.INVESTMENT, label: '投資' },
                  { value: AbroadConsultantPropose.PERSONAL, label: '自用' },
                ]}
                colorScheme={'white'}
              />
              <HookFormError errors={errors} name={'propose'} />
            </div>
            <div className={'mb-2 sm:mb-0 sm:flex-1'}>
              <Label darkMode required>
                預算（台幣）
              </Label>
              <FunwooRadio
                control={control}
                name={'budget'}
                rules={{ required: { value: true, message: '此欄位為必填' } }}
                options={budgetOptions}
                colorScheme={'white'}
              />
              <HookFormError errors={errors} name={'budget'} />
            </div>
          </div>
          <div className={'sm:flex sm:mb-2'}>
            <div className={'mb-2 sm:mr-14 sm:mb-0 sm:flex-1'}>
              <Label darkMode required={false}>
                有無美國居留身份？
              </Label>
              <FunwooRadio
                control={control}
                name={'haveIdentity'}
                options={[
                  { value: 'true', label: '有' },
                  { value: 'false', label: '無' },
                ]}
                colorScheme={'white'}
              />
              <HookFormError errors={errors} name={'haveIdentity'} />
            </div>
            <div className={'mb-2 sm:mb-0 sm:flex-1'}>
              <Label darkMode>有無美國置產經驗？</Label>
              <FunwooRadio
                control={control}
                name={'haveExperienced'}
                rules={{ required: { value: true, message: '此欄位為必填' } }}
                options={[
                  { value: 'true', label: '有' },
                  { value: 'false', label: '無' },
                ]}
                colorScheme={'white'}
              />
              <HookFormError errors={errors} name={'haveExperienced'} />
            </div>
          </div>
          <div className={'sm:flex sm:mb-2'}>
            <div className={'mb-2 sm:mr-14 sm:mb-0 sm:flex-1'}>
              <Label darkMode>有興趣的美國城市</Label>
              <PopupSelect
                label={'有興趣的美國城市'}
                confirmWording={'選擇城市'}
                control={control}
                name={'interestCity'}
                rules={{ required: { value: true, message: '此欄位為必填' } }}
                options={interestingCityOptions}
                isMultiple
              />
              <HookFormError errors={errors} name={'interestCity'} />
            </div>
            <div className={'mb-2 sm:mb-0 sm:flex-1'}>
              <Label darkMode>有興趣的主題</Label>
              <PopupSelect
                label={'有興趣的主題'}
                confirmWording={'選擇主題'}
                control={control}
                name={'interestTopic'}
                rules={{ required: { value: true, message: '此欄位為必填' } }}
                options={interestTopicOptions}
              />
              <HookFormError errors={errors} name={'interestTopic'} />
            </div>
          </div>
        </div>
        <div className={'sm:flex'}>
          <div className={'mb-2 sm:mr-14 sm:mb-0 sm:flex-1'}>
            <Label darkMode>選擇參與講座的場次</Label>
            <PopupSelect
              label={'選擇場次'}
              confirmWording={'確認場次'}
              control={control}
              name={'desireConferenceLocation'}
              rules={{ required: { value: true, message: '此欄位為必填' } }}
              options={conferenceLocationOptions}
            />
            <HookFormError errors={errors} name={'desireConferenceLocation'} />
          </div>
          <div className={'sm:flex-1'}>
            <WhereDoYouKnowUsFrom popupMode darkMode />
          </div>
        </div>
        <p className={'mb-2 py-2 text-gold border-b border-solid border-gold'}>聯絡方式</p>
        <div className={'sm:flex sm:mb-2'}>
          <div className={'mb-2 sm:mr-14 sm:mb-0 sm:flex-1'}>
            <NameField darkMode labelRight={'（本活動採實名制預約 ， 為確保您的參加權益 ， 請填寫出席者的真實全名）'} />
          </div>
          <div className={'mb-2 sm:mb-0 sm:flex-1'}>
            <PhoneField darkMode />
          </div>
        </div>
        <div className={'sm:flex sm:mb-2'}>
          <div className={'mb-2 sm:mr-14 sm:mb-0 sm:flex-1'}>
            <EmailField darkMode required />
          </div>
          <div className={'mb-2 sm:mb-0 sm:flex-1'}>
            <Label darkMode required={false}>
              其他購屋需求或問題
            </Label>
            <Input darkMode {...register('comment')} />
          </div>
        </div>
        <Agreement darkMode />
        <div className={'flex justify-center items-center py-3'}>
          <Button
            type={'submit'}
            className={
              'py-3 px-12 disabled:text-gray500 text-white border border-solid disabled:border-gray500 border-white'
            }
            isLoading={isSubmitting}
          >
            送出資料
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default ConsultantForm;

const FormContainer: React.FC<{
  id?: string;
  showTitle?: boolean;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  title: string;
  wording: string;
  disableTopDivider?: boolean;
}> = ({ id, showTitle, children, onSubmit, title, wording, disableTopDivider = false }) => {
  const {
    formState: { isValid },
  } = useFormContext<BasicFormValues>();

  return (
    <div id={id} className={classNames('pb-4 sm:px-6', { 'py-4': disableTopDivider })}>
      <ConditionalFragment condition={!showTitle && !disableTopDivider}>
        <div className={'flex items-center justify-center py-4'}>
          <div className={'w-40 h-1 bg-black'} />
        </div>
      </ConditionalFragment>
      <ConditionalFragment condition={showTitle}>
        <p className={'py-2 text-xl text-gray900 text-center font-medium'}>{title}</p>
      </ConditionalFragment>
      <p className={'md:text-center text-gray700'}>{wording}</p>
      <form className={'flex flex-col mx-auto py-4 max-w-[56.75rem]'} onSubmit={onSubmit}>
        {chunk(React.Children.toArray(children), 2).map((row, index) => (
          <div key={index} className={'flex flex-col sm:flex-row mb-2 w-full'}>
            {row.map((child, _index) => (
              <React.Fragment key={_index}>{child}</React.Fragment>
            ))}
          </div>
        ))}
        <Agreement className={'mb-3'} />
        <div className={'mx-auto'}>
          <button
            disabled={!isValid}
            className={'w-40 h-12 disabled:text-gray500 border border-solid disabled:border-gray500 border-gray900'}
          >
            送出資料
          </button>
        </div>
      </form>
    </div>
  );
};

const FieldSection: React.FC = ({ children }) => {
  return <div className={'flex-1 sm:last:ml-12'}>{children}</div>;
};

const Label: React.FC<{ required?: boolean; darkMode?: boolean; labelRight?: string | React.ReactNode }> = ({
  children,
  darkMode = false,
  required = true,
  labelRight,
}) => {
  return (
    <label className={classNames('py-2 text-sm block', { 'text-gray700': !darkMode, 'text-white': darkMode })}>
      {children}
      <ConditionalFragment condition={required}>
        <span className={'text-[#e53e3e]'}>*</span>
      </ConditionalFragment>
      {labelRight}
    </label>
  );
};

const Input = forwardRef<HTMLInputElement, JSX.IntrinsicElements['input'] & { isValid?: boolean; darkMode?: boolean }>(
  ({ className, isValid = true, darkMode = false, ...props }, ref) => {
    const {
      formState: { errors },
    } = useFormContext();

    return (
      <input
        className={classNames(className, 'px-3 w-full h-10', 'border border-solid', {
          'border-gray300': !darkMode && isValid,
          'border-red': isFalse(isValid) || isSet(errors?.[props.name ?? '']),
          'border-white': darkMode && isValid,
          'text-white bg-black': darkMode,
        })}
        {...props}
        ref={ref}
      />
    );
  }
);

interface FunwooCheckboxComponent extends FCWithoutComponent {
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    props: PropsWithChildren<
      UseControllerProps<TFieldValues, TName> & {
        options: Array<{ value: FieldPathValue<TFieldValues, TName>[number]; label: string }>;
      }
    >,
    context?: any
  ): ReturnType<React.FC>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FunwooCheckbox: FunwooCheckboxComponent = ({ options, ...props }) => {
  const {
    field: { onChange, onBlur, value: values = [] },
    fieldState: { error },
  } = useController(props);

  return (
    <CheckboxGroup colorScheme={'whiteAlpha'} onChange={onChange} value={values} isNative>
      <Stack spacing={[0, 4]} direction={['column', 'row']} flexWrap={'wrap'}>
        {options.map(({ value, label }) => (
          <Checkbox
            key={value}
            className={'py-2'}
            value={value}
            onBlur={onBlur}
            // @ts-ignore
            isChecked={(values as [])?.includes(value)}
            data-error={isSet(error)}
          >
            {label}
          </Checkbox>
        ))}
      </Stack>
    </CheckboxGroup>
  );
};

interface FunwooRadioComponent<ExtraProps = {}> extends FCWithoutComponent {
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    props: PropsWithChildren<
      UseControllerProps<TFieldValues, TName> & {
        options: Array<{ value: FieldPathValue<TFieldValues, TName>; label: string }>;
        colorScheme?: 'white' | 'black';
      } & ExtraProps
    >,
    context?: any
  ): ReturnType<React.FC>;
}

const FunwooRadio: FunwooRadioComponent = ({ colorScheme = 'black', options = [], ...props }) => {
  const {
    field: { onChange, onBlur, value = '' },
    fieldState: { error },
  } = useController(props);

  useEffect(() => {
    if (!props.defaultValue) return;
    if (options.some(({ value }) => value === props.defaultValue)) {
      onChange(props.defaultValue);
    } else {
      onChange(options[0]?.value);
    }
  }, [JSON.stringify(options)]);

  return (
    <RadioGroup className={`radio_group_${colorScheme}`} onChange={onChange} value={value} isNative unselectable={'on'}>
      <Stack spacing={[0, 4]} direction={['column', 'row']} flexWrap={'wrap'}>
        {options.map(({ value: optionValue, label }) => (
          <Radio key={optionValue} value={optionValue} py={2} onBlur={onBlur} data-error={isSet(error)}>
            {label}
          </Radio>
        ))}
      </Stack>
    </RadioGroup>
  );
};

const FormSummitedPopUp: React.FC<{ show: boolean; close: () => void; title: string; buttonText: string }> = ({
  show,
  close,
  title,
  buttonText,
}) => {
  // const handleContact = useCallback(() => {
  //   window.open(`tel:${process.env.NEXT_PUBLIC_FUNWOO_TAIWAN_CELLPHONE}`);
  //   sendLineTagEvent(LineEventTypes.phone_call);
  // }, []);

  return (
    <Modal show={show} zIndex={60} className={'pb-0'}>
      <div className={'flex flex-col w-full h-full sm:w-[27.375rem] sm:h-fit bg-white'}>
        <div className={'flex flex-col items-center justify-center flex-1'}>
          <div
            className={'hidden sm:flex items-center justify-between pl-4 w-full border-b border-solid border-gray900'}
          >
            <p className={'text-xl font-medium'}>{title}</p>
            <button type='button' className={'w-12 h-12'} onClick={close}>
              <CloseIcon />
            </button>
          </div>
          <div className={'sm:pb-8 sm:pt-6'}>
            <div className={'py-2 px-4'}>
              <p className={'text-2xl text-gray700 text-center font-medium'}>感謝您，我們收到資料了</p>
            </div>
            <div className={'py-2 px-4'}>
              <p className={'text-gray700 text-center'}>
                房產顧問會盡快和您聯絡
                {/*<br />*/}
                {/*若有任何問題，歡迎直接與我們聯絡。*/}
                {/*<span className={'hidden sm:inline'}>（ 02-7752-3755 ）</span>*/}
              </p>
            </div>
            {/*<div className={'sm:hidden py-2'}>*/}
            {/*  <button*/}
            {/*    onClick={handleContact}*/}
            {/*    type='button'*/}
            {/*    className={'flex items-center py-2 px-3 mx-auto border border-solid border-gray900'}*/}
            {/*  >*/}
            {/*    <PhoneIcon className={'mr-1.5'} />*/}
            {/*    聯絡客服經理*/}
            {/*  </button>*/}
            {/*</div>*/}
          </div>
        </div>
        <div className={'sm:hidden py-3 px-4'}>
          <button onClick={close} type={'button'} className={'py-3 w-full h-full bg-black text-white font-medium'}>
            {buttonText}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const NameField: React.FC<{ darkMode?: boolean; labelRight?: string | React.ReactNode }> = ({
  darkMode,
  labelRight,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <React.Fragment>
      <Label darkMode={darkMode} required labelRight={labelRight}>
        姓名
      </Label>
      <Input darkMode={darkMode} {...register('name', { required: { value: true, message: '此欄位為必填' } })} />
      <HookFormError errors={errors} name={'name'} />
    </React.Fragment>
  );
};

const PhoneField: React.FC<{ darkMode?: boolean }> = ({ darkMode }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <React.Fragment>
      <Label darkMode={darkMode} required>
        手機
      </Label>
      <HStack>
        <Input
          darkMode={darkMode}
          className={'flex-1'}
          inputMode={'numeric'}
          {...register('phoneCountryCode', { validate: digitalOnly })}
          placeholder={'886'}
          defaultValue={'886'}
        />
        <Input
          darkMode={darkMode}
          className={'flex-[3]'}
          inputMode={'numeric'}
          {...register('phone', {
            required: {
              value: true,
              message: '此欄位為必填',
            },
            validate: phoneValidator,
          })}
          placeholder={'09123456789'}
        />
      </HStack>
      <HookFormError errors={errors} name={'phoneCountryCode'} />
      <HookFormError errors={errors} name={'phone'} />
    </React.Fragment>
  );
};

const EmailField: React.FC<{ darkMode?: boolean; required?: boolean }> = ({ darkMode, required = false }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <React.Fragment>
      <Label darkMode={darkMode} required={required}>
        電子郵件
      </Label>
      <Input
        darkMode={darkMode}
        inputMode={'email'}
        {...register('email', {
          required: required ? { value: true, message: '此欄位為必填' } : undefined,
          validate: emailValidator,
        })}
      />
      <HookFormError errors={errors} name={'email'} />
    </React.Fragment>
  );
};

const Agreement: React.FC<{
  darkMode?: boolean;
  className?: string;
  useSquareBox?: boolean;
  startLeft?: boolean;
}> = ({ darkMode, className, useSquareBox, startLeft }) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({
    control,
    name: 'agree',
    rules: {
      validate: isTrue,
    },
  });

  const onRadioChange = useCallback<React.MouseEventHandler<HTMLDivElement>>((event) => {
    const checked = event.currentTarget.dataset.checked === 'true';
    onChange(!checked);
  }, []);

  return (
    <div
      onClick={onRadioChange}
      data-checked={value}
      className={classNames(
        className,
        'flex items-center py-4',
        'hover:cursor-pointer',
        startLeft ? 'justify-start' : 'justify-center'
      )}
    >
      {useSquareBox ? (
        <Flexbox
          align={'center'}
          justify={'center'}
          className={classNames(
            'mr-2',
            'h-4 w-4 border rounded-sm',
            fieldState.invalid
              ? 'border-red'
              : darkMode
              ? 'border-white'
              : value
              ? 'border-[rgb(96,165,250)]'
              : 'border-gray500',
            value && 'bg-[rgb(96,165,250)]'
          )}
        >
          {value && <FaCheck size={'10'} className={'text-white'} />}
        </Flexbox>
      ) : (
        <div
          className={classNames(
            'mr-2',
            'w-4 h-4',
            'rounded-lg',
            value ? 'border-[5px]' : 'border-2',
            fieldState.invalid ? 'border-red' : darkMode ? 'border-white' : 'border-black',
            'flex-shrink-0'
          )}
        />
      )}
      <span className={'text-sm'}>
        我已閱讀{' '}
        <Link href={'/terms-of-service'} passHref>
          <a
            className={classNames({ 'text-gray700': !darkMode }, 'underline')}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            服務條款
          </a>
        </Link>{' '}
        與{' '}
        <Link href={'/privacy-policy'} passHref>
          <a
            className={classNames({ 'text-gray700': !darkMode }, 'underline')}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            隱私權聲明
          </a>
        </Link>
        ，並同意 FUNWOO 依照該聲明使用我的相關資訊。
        <span className={'text-[#e53e3e]'}>*</span>
      </span>
    </div>
  );
};

interface PopupSelectComponent<ExtraProps = {}> extends FCWithoutComponent {
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    props: PropsWithChildren<
      UseControllerProps<TFieldValues, TName> &
        (
          | {
              options: Array<{ value: FieldPathValue<TFieldValues, TName>; label: string }>;
              isMultiple?: false;
            }
          | {
              options: Array<{ value: FieldPathValue<TFieldValues, TName>[number]; label: string }>;
              isMultiple: true;
            }
        ) &
        ExtraProps
    >,
    context?: any
  ): ReturnType<React.FC>;
}

const PopupSelect: PopupSelectComponent<{
  placeholder?: string;
  label?: string;
  confirmWording?: string;
  darkMode?: boolean;
}> = ({ label, placeholder, options = [], darkMode = true, confirmWording, isMultiple = false, ...props }) => {
  const [isPopupShow, setIsPopupShow] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<unknown>(undefined);

  const {
    field: { onChange, onBlur, value = '', ref },
    fieldState: { error },
  } = useController(props);

  useEffect(() => {
    setCurrentValue(value);
  }, [value, isPopupShow]);

  useEffect(() => {
    if (!props.defaultValue) return;
    if (options.some(({ value }) => value === props.defaultValue)) {
      if (isMultiple) onChange([props.defaultValue]);
      else onChange(props.defaultValue);
    } else {
      if (options[0]) {
        if (isMultiple) onChange([options[0].value]);
        else onChange(options[0].value);
      }
    }
  }, [JSON.stringify(options), isMultiple]);

  const displayValue = useMemo(() => {
    let target: string | undefined;
    if (isMultiple) {
      target = (options as Array<Option<unknown>>)
        .filter(({ value: _value }) => (value as Array<unknown>).includes(_value))
        .map(({ label }) => label)
        .join('、');
    } else {
      target = (options as Array<Option<unknown>>).find(({ value: _value }) => value === _value)?.label;
    }

    if (!target) return placeholder;

    return target;
  }, [value, options]);

  const triggerPopup = useCallback(() => setIsPopupShow((prev) => !prev), []);

  const onCurrentValueChange = useCallback(
    (value: unknown) => {
      if (isMultiple) {
        setCurrentValue(value);
      } else {
        onChange(value);
        triggerPopup();
      }
    },
    [isMultiple]
  );

  const onConfirm = useCallback(() => {
    onChange(currentValue);
    triggerPopup();
  }, [currentValue]);

  return (
    <React.Fragment>
      <Modal zIndex={999} show={isPopupShow} onClose={triggerPopup}>
        <div
          className={
            'mt-auto sm:mt-0 flex flex-col justify-end sm:justify-center sm:items-center w-full h-full max-h-[88vh] sm:max-h-[352px]'
          }
        >
          <div className={'flex flex-col max-h-full sm:h-auto w-full sm:max-w-[560px] bg-white overflow-hidden'}>
            <div className={'flex flex-col pt-4 sm:pt-0 flex-1 overflow-hidden'}>
              <div className={'flex items-center py-2 px-6 sm:py-4 sm:px-8'}>
                <p className={'hidden invisible sm:block sm:visible sm:mr-auto text-xl leading-[2rem] font-medium'}>
                  {label}
                </p>
                <Button onClick={triggerPopup} variant={'none'} w={6} minW={0} h={6} px={0} py={0}>
                  <CloseIcon />
                </Button>
              </div>
              <div className={'flex-1 border-t border-solid border-gray300 sm:border-black overflow-hidden'}>
                <ScrollableMaskContainer className={'pt-4 sm:pt-0'} direction={'vertical'}>
                  {isMultiple ? (
                    <CustomCheckbox
                      options={options}
                      value={currentValue as Array<unknown>}
                      onChange={onCurrentValueChange}
                      fixColumnStyle
                    />
                  ) : (
                    <CustomRadio
                      options={options}
                      onChange={onCurrentValueChange}
                      activeIdx={currentValue}
                      fixColumnStyle
                    />
                  )}
                </ScrollableMaskContainer>
              </div>
            </div>
            <ConditionalFragment condition={isMultiple}>
              <div className={'py-2 px-4 sm:py-3 sm:px-8 border-t border-solid border-gray300'}>
                <Button w={{ base: 'full', sm: 'auto' }} h={12} onClick={onConfirm}>
                  {confirmWording}
                </Button>
              </div>
            </ConditionalFragment>
          </div>
        </div>
      </Modal>
      <div
        ref={ref}
        onBlur={onBlur}
        onClick={triggerPopup}
        className={classNames(
          'flex items-center justify-between',
          'px-4 py-2',
          'border border-solid',
          'sm:hover:cursor-pointer',
          {
            'border-red': isSet(error),
            [!darkMode ? 'border-gray300' : 'border-white']: isNotSet(error),
            'bg-white': !darkMode,
          }
        )}
      >
        <span>{displayValue}</span>
        <ChevronDownIcon
          fontSize={'24px'}
          className={classNames('transform transition-transform', { 'rotate-180': isPopupShow })}
        />
      </div>
    </React.Fragment>
  );
};

const PopupFormContainer: React.FC<{
  show: boolean;
  onClose: () => void;
  onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined;
  title: string;
  isValid: boolean;
  isSubmitting: boolean;
}> = ({ show, onClose, onSubmit, title, children, isSubmitting, isValid }) => {
  return (
    <Modal show={show} zIndex={1000} className={StyleModule['form']}>
      <form
        className={classNames(
          'flex flex-col',
          'w-full max-w-[35rem] h-stretch max-h-stretch md:h-fit',
          'bg-white overflow-hidden'
        )}
        onSubmit={onSubmit}
      >
        <div
          className={classNames(
            'relative',
            'flex items-center justify-center md:justify-start',
            'py-2 md:py-4 mb-1',
            'md:border-b md:border-solid md:border-black'
          )}
        >
          <button
            type={'button'}
            onClick={onClose}
            className={'absolute left-0 md:left-auto md:right-0 flex justify-center items-center w-12 h-12'}
          >
            <span className={'md:hidden md:invisible'}>
              <BsArrowLeftShort size={24} />
            </span>
            <span className={'hidden invisible md:block md:visible'}>
              <CloseIcon size={24} />
            </span>
          </button>
          <h2 className={'md:pl-8 text-xl leading-8 text-center font-medium'}>{title}</h2>
        </div>
        <div className={'flex-1 md:flex-none px-4 md:pt-4 md:px-6 md:border-b md:border-solid overflow-scroll'}>
          {children}
        </div>
        <div className={'p-3 md:px-8'}>
          <button
            disabled={!isValid || isSubmitting}
            className={'block w-full md:w-24 h-12 disabled:bg-opacity-25 text-white bg-gray900'}
          >
            <ConditionalFragment condition={!isSubmitting}>送出資料</ConditionalFragment>
            <ConditionalFragment condition={isSubmitting}>
              <SpinnerIcon className={'animate-spin'} />
            </ConditionalFragment>
          </button>
        </div>
      </form>
    </Modal>
  );
};

export const BuildingRequirementConsultantForm: React.FC<{
  show: boolean;
  onClose: () => void;
  floorPlayTypes: Array<string>;
  buildingId: string;
}> = ({ show, onClose, floorPlayTypes, buildingId }) => {
  const [showSubmitSucceed, setShowSubmitSucceed] = useState<boolean>(false);

  const methods = useForm<SendInterestedBuildingMailFormValues>({
    mode: 'all',
    defaultValues: {
      phoneCountryCode: 886,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
    reset,
  } = methods;

  const triggerSubmitSucceed = useCallback(() => {
    onClose();
    setShowSubmitSucceed((prev) => !prev);
  }, []);

  const onSubmit = useCallback<SubmitHandler<SendInterestedBuildingMailFormValues>>(
    async ({ phone, phoneCountryCode, ...formValues }) => {
      await FunwooAPI.mailApi.sendEmail({
        type: 'INTERESTED_BUILDING',
        ...formValues,
        phone: `${phoneCountryCode}-${phone}`,
        buildingId,
      });
      reset();
      triggerSubmitSucceed();
    },
    [buildingId]
  );

  if (showSubmitSucceed)
    return (
      <FormSummitedPopUp
        show={showSubmitSucceed}
        close={triggerSubmitSucceed}
        title={'購屋需求諮詢'}
        buttonText={'返回建案頁面'}
      />
    );

  return (
    <FormProvider {...methods}>
      <PopupFormContainer
        show={show}
        onClose={onClose}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit(onSubmit)}
        title={'填寫購屋需求'}
        isValid={isValid}
      >
        <p className={'py-2 mb-2 text-gray700 md:text-sm leading-6'}>請留下您的資訊，我們會於 24 小時內和你聯絡。</p>
        <FunwooRadio
          control={control}
          name={'interestedUnitType'}
          options={floorPlayTypes.map((type) => ({
            value: type,
            label: capitalize(type),
          }))}
        />
        <NameField />
        <PhoneField />
        <EmailField required />
        <WhereDoYouKnowUsFrom />
        <Agreement />
      </PopupFormContainer>
    </FormProvider>
  );
};

export const BuyHouseConsultantPopup: React.FC<{ show: boolean; onClose: () => void }> = ({ show, onClose }) => {
  const [showSubmitSucceed, setShowSubmitSucceed] = useState<boolean>(false);

  const methods = useForm<SendBuyHouseConsultantMailFormValues>({
    mode: 'all',
    defaultValues: {
      phoneCountryCode: 886,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting },
    reset,
  } = methods;

  const triggerSubmitSucceed = useCallback(() => {
    onClose();
    setShowSubmitSucceed((prev) => !prev);
  }, []);

  const onSubmit = useCallback<SubmitHandler<SendBuyHouseConsultantMailFormValues>>(
    async ({ phone, phoneCountryCode, ...formValues }) => {
      await FunwooAPI.mailApi.sendEmail({
        type: 'BUY_HOUSE',
        ...formValues,
        phone: `${phoneCountryCode}-${phone}`,
      });
      reset();
      triggerSubmitSucceed();
    },
    []
  );

  if (showSubmitSucceed)
    return (
      <FormSummitedPopUp
        show={showSubmitSucceed}
        close={triggerSubmitSucceed}
        title={'購屋需求諮詢'}
        buttonText={'返回買家頁面'}
      />
    );

  return (
    <FormProvider {...methods}>
      <PopupFormContainer
        show={show}
        onClose={onClose}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit(onSubmit)}
        title={'填寫購屋需求'}
        isValid={isValid}
      >
        <p className={'py-2 mb-2 text-gray700 md:text-sm leading-6'}>
          超過百位買家使用的專業買代尋服務，留下您的資訊，我們將於 24 小時內聯絡您
        </p>
        <NameField />
        <PhoneField />
        <EmailField required />
        <div>
          <Label required>其他購屋需求（區域、預算、室內坪數）或問題</Label>
          <Input {...register('comment')} />
        </div>
        <WhereDoYouKnowUsFrom />
        <Agreement />
      </PopupFormContainer>
    </FormProvider>
  );
};

export const WhereDoYouKnowUsFrom: React.FC<{ popupMode?: boolean; darkMode?: boolean }> = ({
  popupMode,
  darkMode,
}) => {
  const { control } = useFormContext<BasicFormValues>();

  const options = useRef<Array<Option>>([
    { label: '新聞媒體', value: '新聞媒體' },
    { label: '親友推薦', value: '親友推薦' },
    { label: 'Facebook', value: 'Facebook' },
    { label: 'Instagram', value: 'Instagram' },
    { label: 'YouTube', value: 'YouTube' },
    { label: 'Google Search', value: 'Google Search' },
    { label: 'LINE', value: 'LINE' },
    { label: '591廣告', value: '591廣告' },
  ]);

  if (popupMode) {
    return (
      <div>
        <Label darkMode={darkMode}>您從哪個管道得知FUNWOO?</Label>
        <PopupSelect
          name={'whereDoYouKnowUsFrom'}
          control={control}
          rules={{ required: true }}
          options={options.current}
          isMultiple={false}
          label={'選擇管道'}
          darkMode={darkMode}
        />
      </div>
    );
  }

  return (
    <div>
      <Label darkMode={darkMode}>您從哪個管道得知FUNWOO?</Label>
      <FunwooSelect
        name={'whereDoYouKnowUsFrom'}
        control={control}
        rules={{ required: true }}
        options={options.current}
        className={'bg-white'}
      />
    </div>
  );
};

const consultantTimeOptions: Array<Option> = [
  {
    label: '平日上午 10:00-11:30',
    value: '平日上午 10:00-11:30',
  },
  {
    label: '平日下午 14:00-15:30',
    value: '平日下午 14:00-15:30',
  },
  {
    label: '週六上午 10:00-11:30',
    value: '週六上午 10:00-11:30',
  },
];

const purposesForPersonalConsultation: Array<Option> = [
  { label: '投資', value: '投資' },
  { label: '自住', value: '自住' },
  { label: '子女留學 / 就業', value: '子女留學 / 就業' },
];

const budgetOptionsForPersonalConsultation: Array<Option> = [
  { value: '3000w-5000w', label: '3000 萬 - 5000 萬' },
  { value: '5000w-1e', label: '5000 萬 - 1 億' },
  { value: '1e-2e', label: '1-2億以上' },
  { value: '2e-above', label: '2億以上' },
];

const dDayOptions: Array<Option> = [
  { label: '3個月內', value: '3個月內' },
  { label: '1年內', value: '1年內' },
  { label: '1年以上', value: '1年以上' },
  { label: '尚未決定', value: '尚未決定' },
];

const floorPlanOptions: Array<Option> = [
  { label: 'Studio', value: 'Studio' },
  { label: '1房', value: '1房' },
  { label: '2房', value: '2房' },
  { label: '3房以上', value: '3房以上' },
];

const getPersonalConsultantFormInitialValues = (): SendPersonalConsultationFormValues => {
  return {
    name: '',
    phoneCountryCode: 886,
    phone: '',
    email: '',
    agree: false,
    // availableTime: availableTimeOptions[0].value,
    consultantTime: consultantTimeOptions[0].value,
    purpose: purposesForPersonalConsultation[0].value,
    haveExperienced: 'true',
    budget: budgetOptionsForPersonalConsultation[0].value,
    dDay: dDayOptions[0].value,
    interestedCity: [interestingCityOptions[0].value],
    interestedFloorPlan: [floorPlanOptions[0].value],
    whereDoYouKnowUsFrom: 'Facebook',
    currentlyLocation: '',
  };
};

export const PersonalConsultantForm = () => {
  const [showSubmitSucceed, setShowSubmitSucceed] = useState<boolean>(false);

  const methods = useForm<SendPersonalConsultationFormValues>({
    mode: 'onChange',
    defaultValues: getPersonalConsultantFormInitialValues(),
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    register,
  } = methods;

  const router = useRouter();

  const triggerSubmitSucceed = useCallback(() => setShowSubmitSucceed((prev) => !prev), []);

  const onSubmit = useCallback<SubmitHandler<SendPersonalConsultationFormValues>>(
    async ({ phone, phoneCountryCode, purpose, agree: _, ...formValues }) => {
      const { utm_source, utm_campaign, utm_content, utm_medium } = (router.query ?? {}) as UTM;

      await FunwooAPI.mailApi.sendEmail({
        type: 'PERSONAL_CONSULTATION',
        ...formValues,
        phone: `${phoneCountryCode}-${phone}`,
        purpose,
        utm: {
          utm_source,
          utm_campaign,
          utm_content,
          utm_medium,
          userAgent: window.navigator.userAgent,
        },
      });
      reset();
      triggerSubmitSucceed();
    },
    [router, reset]
  );

  return (
    <FormProvider {...methods}>
      <GreetingPopup
        show={showSubmitSucceed}
        // show
        onClose={triggerSubmitSucceed}
        title={'[ 專人諮詢 ] 感謝您填寫資料！'}
      >
        <p className={'font-medium text-base'}>
          我們已收到您預約美國不動產專人諮詢
          <br />
          客服專員將於工作日白天與您電話聯繫
        </p>
      </GreetingPopup>
      <form onSubmit={handleSubmit(onSubmit, console.log)} className={StyleModule['form']}>
        <p className={'mb-2 py-2'}>留下您的資訊，我們將於 24 小時內聯絡您</p>
        <p className={'mb-2 py-2 text-gold border-b border-solid border-gold'}>聯絡方式</p>
        <div className={'sm:flex sm:mb-2'}>
          <div className={'mb-2 sm:mr-14 sm:mb-0 sm:flex-1'}>
            <NameField darkMode labelRight={'（本活動採實名制預約 ， 為確保您的參加權益 ， 請填寫出席者的真實全名）'} />
          </div>
          <div className={'mb-2 sm:mb-0 sm:flex-1'}>
            <PhoneField darkMode />
          </div>
        </div>
        <div className={'sm:flex sm:mb-2'}>
          <div className={'mb-2 sm:mr-14 sm:mb-0 sm:flex-1'}>
            <EmailField darkMode required />
          </div>
          <div className={'mb-2 sm:mb-0 sm:flex-1'}>
            <Label darkMode>目前居住城市</Label>
            <Input
              darkMode
              {...register('currentlyLocation', {
                required: { value: true, message: '此欄位為必填' },
              })}
            />
            <HookFormError errors={errors} name={'email'} />
          </div>
        </div>
        <p className={'mb-2 py-2 text-gold border-b border-solid border-gold'}>置產需求</p>
        <div>
          <div className={'sm:flex sm:mb-2'}>
            <div className={'mb-2 sm:mr-14 sm:mb-0 sm:flex-1'}>
              <Label darkMode required>
                美國置產用途
              </Label>
              <FunwooRadio
                control={control}
                name={'purpose'}
                rules={{ required: { value: true, message: '此欄位為必填' } }}
                options={purposesForPersonalConsultation}
                colorScheme={'white'}
              />
              <HookFormError errors={errors} name={'purpose'} />
            </div>
            <div className={'mb-2 sm:mb-0 sm:flex-1'}>
              <Label darkMode>有無美國置產經驗？</Label>
              <FunwooRadio
                control={control}
                name={'haveExperienced'}
                rules={{ required: { value: true, message: '此欄位為必填' } }}
                options={[
                  { value: 'true', label: '有' },
                  { value: 'false', label: '無' },
                ]}
                colorScheme={'white'}
              />
              <HookFormError errors={errors} name={'haveExperienced'} />
            </div>
          </div>
          <div className={'sm:flex sm:mb-2'}>
            <div className={'mb-2 sm:mr-14 sm:mb-0 sm:flex-1'}>
              <Label darkMode required>
                預算（台幣）
              </Label>
              <FunwooRadio
                control={control}
                name={'budget'}
                rules={{ required: { value: true, message: '此欄位為必填' } }}
                options={budgetOptionsForPersonalConsultation}
                colorScheme={'white'}
              />
              <HookFormError errors={errors} name={'budget'} />
            </div>
            <div className={'mb-2 sm:mb-0 sm:flex-1'}>
              <Label darkMode required>
                計劃購買時程
              </Label>
              <FunwooRadio
                control={control}
                name={'dDay'}
                rules={{ required: { value: true, message: '此欄位為必填' } }}
                options={dDayOptions}
                colorScheme={'white'}
              />
              <HookFormError errors={errors} name={'dDay'} />
            </div>
          </div>
          <div className={'sm:flex sm:mb-2'}>
            <div className={'mb-2 sm:mr-14 sm:mb-0 sm:flex-1'}>
              <Label darkMode>有興趣的美國城市</Label>
              <PopupSelect
                label={'置產城市'}
                confirmWording={'選擇城市'}
                control={control}
                name={'interestedCity'}
                rules={{ required: { value: true, message: '此欄位為必填' } }}
                options={interestingCityOptions}
                isMultiple
              />
              <HookFormError errors={errors} name={'interestedCity'} />
            </div>
            <div className={'mb-2 sm:mb-0 sm:flex-1'}>
              <Label darkMode>房型需求</Label>
              <PopupSelect
                label={'房型需求'}
                confirmWording={'選擇房型'}
                control={control}
                name={'interestedFloorPlan'}
                rules={{ required: { value: true, message: '此欄位為必填' } }}
                options={floorPlanOptions}
                isMultiple
              />
              <HookFormError errors={errors} name={'interestedFloorPlan'} />
            </div>
          </div>
          <div className={'sm:flex sm:mb-2'}>
            <div className={'mb-2 sm:mr-14 sm:mb-0 sm:flex-1'}>
              <Label darkMode>諮詢時段</Label>
              <PopupSelect
                label={'諮詢時段'}
                confirmWording={'選擇時段'}
                control={control}
                name={'consultantTime'}
                rules={{ required: { value: true, message: '此欄位為必填' } }}
                options={consultantTimeOptions}
              />
              <HookFormError errors={errors} name={'consultantTime'} />
            </div>
            <div className={'mb-2 sm:mb-0 sm:flex-1'}>
              <WhereDoYouKnowUsFrom popupMode darkMode />
            </div>
          </div>
        </div>
        <Agreement darkMode />
        <div className={'flex justify-center items-center py-3'}>
          <Button
            type={'submit'}
            className={
              'py-3 px-12 disabled:text-gray500 text-white border border-solid disabled:border-gray500 border-white'
            }
            isLoading={isSubmitting}
          >
            送出資料
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

const GreetingPopup: React.FC<PropsWithChildren<{ show: boolean; onClose: () => void; title: string }>> = ({
  show,
  onClose,
  title,
  children,
}) => {
  return (
    <ChakraModal isOpen={show} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        w={{
          base: 'full',
          sm: 'auto',
        }}
        h={{
          base: 'full',
          sm: 'auto',
        }}
        border={{
          sm: '2px solid #000',
        }}
        borderRadius={{
          sm: '0',
        }}
      >
        <ModalHeader
          borderBottom={2}
          borderColor={'gray.900'}
          borderBottomStyle={'solid'}
          display={{
            base: 'none',
            sm: 'block',
          }}
          px={{
            sm: 4,
          }}
          py={{
            sm: 2,
          }}
        >
          {title}
        </ModalHeader>
        <ModalCloseButton
          display={{
            base: 'none',
            sm: 'block',
          }}
          top={1.5}
        />
        <ModalBody
          display={{ base: 'flex' }}
          flexDirection={{
            base: 'column',
          }}
          alignItems={{
            base: 'start',
          }}
          justifyContent={{
            base: 'center',
          }}
          px={{
            base: 8,
            sm: 4,
          }}
          py={{
            base: 10,
            sm: 4,
          }}
          color={'gray.900'}
        >
          <p className={classNames('text-xl font-semibold pb-12 mt-auto', 'sm:hidden sm:invisible')}>{title}</p>
          {children}
          <div className={classNames('mt-auto', 'sm:flex sm:items-center sm:space-x-8 sm:mt-12')}>
            <div>
              <p className={classNames('text-gray500 text-sm')}>
                FUNWOO 客服專線{' '}
                <a href={'tel:02-7752-3755'} className={'underline underline-offset-2'}>
                  02-7752-3755
                </a>
                <br />
                或加入
                <a
                  href={'https://line.me/ti/p/@funwoo'}
                  target={'_blank'}
                  rel={'noreferrer noopener'}
                  className={'underline underline-offset-2'}
                >
                  官方 LINE
                </a>{' '}
                獲取更多資訊
              </p>
            </div>
            <Button
              as={'a'}
              href={'https://line.me/ti/p/@funwoo'}
              target={'_blank'}
              rel={'noreferrer noopener'}
              display={{
                base: 'none',
                sm: 'inline-flex',
              }}
            >
              官方 LINE
            </Button>
          </div>
        </ModalBody>
        <ModalFooter
          borderTop={'1px'}
          borderTopColor={'gray.700'}
          borderTopStyle={'solid'}
          display={{
            sm: 'none',
          }}
          visibility={{
            sm: 'hidden',
          }}
        >
          <Button
            type={'button'}
            onClick={onClose}
            w={{
              base: 'full',
            }}
          >
            確認
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};
